<template>
  <a-tabs default-active-key="1" :animated="animated" @change="tabsChange" style="width: 100%;">
    <a-tab-pane key="1" style="width: 100%;">
      <span slot="tab">
        <my-icon type="icon-jihua"></my-icon>
        充放电计划管理
      </span>
      <div style="width: 100%;">
        <div style="width: 100%;">
          <div class="calendar">
            <a-row type="flex" justify="space-between" align="middle" :gutter="[0, 0]"
              style="width: 458px; padding-right: 10px;">
              <div class="calendar_title">
                {{ yearNum }}年{{ monthNum + 1 }}月
              </div>
              <div class="calendar_action">
                <a-button @click="previous(0)" type="primary"
                  style="width: 32px; height: 32px; padding: 0 !important; background: transparent !important; border: 1px solid #30303C; margin-left: 5px;">
                  <a-icon type="left" />
                </a-button>
                <a-button @click="today" type="primary"
                  style=" padding: 0 5px; height: 32px; background: transparent !important; border: 1px solid #30303C; font-size: 11px; !important">
                  今日
                </a-button>
                <a-button @click="next(0)" type="primary"
                  style="width: 32px; height: 32px; padding: 0 !important; background: transparent !important; border: 1px solid #30303C;">
                  <a-icon type="right" />
                </a-button>
                <a-popover placement="bottom" trigger="click" v-model="actionVisible">
                  <template slot="content">
                    <span :style="{ marginRight: restMode.operatingMode == '2' ? '5px' : '20px' }">
                      <a-icon v-show="restMode.operatingMode == '2'" type="check-circle"
                        style="color: #177DDC !important;" />
                    </span><span style="cursor: pointer;" @click="restModeTypeChange(1)">单休计划</span>
                    <br>
                    <span :style="{ marginRight: restMode.operatingMode == '1' ? '5px' : '20px' }">
                      <a-icon v-show="restMode.operatingMode == '1'" type="check-circle"
                        style="color: #177DDC !important;" />
                    </span><span style="cursor: pointer;" @click="restModeTypeChange(2)">双休计划</span>
                  </template>
                  <a-button
                    style="width: 32px; padding: 0 !important; background: transparent !important; border: 1px solid #30303C;">
                    <img src="@a/images/icon/ic_more.png">
                  </a-button>
                </a-popover>
              </div>
            </a-row>
            <a-row type="flex" justify="space-between" align="middle" :gutter="[0, 0]"
              style="width: 458px; padding-right: 10px;" onselectstart="return false;">
              <div style="width: 100%; margin-top: 20px;">
                <ul class="riliHeader">
                  <li style="background: #000;">日</li>
                  <li>一</li>
                  <li>二</li>
                  <li>三</li>
                  <li>四</li>
                  <li>五</li>
                  <li style="background: #000;">六</li>
                </ul>
                <ul class="riliContent" id="riliContent">

                </ul>
              </div>
              <div style="padding: 10px 0; font-size: 12px; color: #B7B8BC;">
                <div
                  style="width: 12px; height: 12px; border-radius: 12px; background: #177DDC; margin: 10px 0; float: left;">
                </div>
                <div style="height: 32px; margin-left: 10px; width: 60px; float: left; line-height: 32px;">
                  工作日策略
                </div>
                <div
                  style="width: 12px; height: 12px; border-radius: 12px; background: #49AA19; margin: 10px 0 10px 15px; float: left;">
                </div>
                <div style="height: 32px; margin-left: 10px; width: 60px; float: left; line-height: 32px;">
                  休息日策略
                </div>
                <div
                  style="width: 12px; height: 12px; border-radius: 12px; background: #F26813; margin: 10px 0 10px 15px; float: left;">
                </div>
                <div style="height: 32px; margin-left: 10px; width: 60px; float: left; line-height: 32px;">
                  检修策略
                </div>
              </div>
            </a-row>
          </div>
          <div style="width: calc(100% - 458px); height: 500px; float: left;" id="plan_strategy_chart">
          </div>
        </div>
      </div>
      <a-modal v-model="dialogFormVisible" title="修改充放电计划" @ok="updatePlan" cancelText="取消" :keyboard="keyboard"
        :maskClosable="keyboard" okText="确认" width="420px">
        <a-form-model layout="inline" :model="restMode">
          <a-form-model-item label="选择日期：">
            <a-date-picker v-model="updatePlanDate" :default-value="updatePlanDate" placeholder="选择日期"
              :format="dateFormat" :locale="datePickerLocale" disabled style="width: 260px;">
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="生产计划：">
            <a-select v-model="restMode.operatingMode" placeholder="生产计划" style="width: 260px;"
              :default-value="restMode.operatingMode">
              <a-select-option value="2">工作日策略</a-select-option>
              <a-select-option value="1">节假日策略</a-select-option>
              <a-select-option value="0">检修策略</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-tab-pane>
    <a-tab-pane key="2" style="width: 100%;">
      <span slot="tab">
        <my-icon type="icon-guanli"></my-icon>
        策略管理
      </span>
      <div style="width: calc(100% - 32px); height: 30px; padding: 10px 16px 0 16px;">
        <a-row type="flex" justify="space-around" align="middle" :gutter="[0, 0]">
          <a-col :span="4">
            <div>
              <a-button type="primary" @click="createFn" :disabled="!isAdmin()">
                新建策略
              </a-button>
            </div>
          </a-col>
          <a-col :span="20">
            <div style="float: right;">
              <a-select v-model="strategyType" placeholder="生产计划" style="width: 160px;" :default-value="strategyType"
                @change="strategyTypeChange">
                <a-select-option value="2">工作日</a-select-option>
                <a-select-option value="1">节假日</a-select-option>
                <a-select-option value="0">检修</a-select-option>
              </a-select>
              <a-button type="primary" style="margin-left:10px;" @click="updateFn" :disabled="!isAdmin()">
                修改
              </a-button>
              <a-button style="margin-left:10px; color: #ff0000 !important; border-color: #ff0000 !important;"
                v-show="strategyType != '0'" @click="delFn" :disabled="!isAdmin()">
                删除
              </a-button>
            </div>
          </a-col>
        </a-row>
      </div>
      <div id="strategy_chart" style="width: 100%; height: 360px;">
      </div>
      <!--<div class="strategy">
			<div class="strategy_name">放电下限：</div>
			<div class="strategy_data">{{dischargeLowerLimit}}%</div>
			<div class="strategy_name">充电上线：</div>
			<div class="strategy_data">{{dischargeUpperLimit}}%</div>
		</div>-->
      <div style="color: #B7B8BC;">
        <a-row style="background: #7C868D;">
          <a-col :span="6" :push="0">
            <div style="text-indent: 30px; font-size: 21px; font-weight: 800;">需量控制</div>
          </a-col>
          <a-col :span="12" :push="0" style="margin-top: 3px;">
            <a-col :span="12" :push="0">
              <div style="width: 100%; text-align: end; font-size: 18px;">
                总需量：
              </div>
            </a-col>
            <a-col :span="12" :push="0">
              <div>
                <a-input-number v-model="totalDemandValue" :min="0" size="small" :max="100000000"
                  :formatter="value => `${value}kW`" :parser="value => value.replace('kW', '')" :disabled="!isAdmin()"/>
              </div>
            </a-col>
          </a-col>
          <a-col :span="6" :push="0" style="margin-top: 3px;">
            <div style="margin-left: calc(100% - 90px);">
              <a-button type="primary" :loading="loading" size="small" @click="setLinkDemand" :disabled="!isAdmin()">
                保存
              </a-button>
            </div>
          </a-col>
        </a-row>
        <a-row v-show="equipList.length > 1">
          <a-col style="margin-top: 15px;" :span="6" :push="0" v-for="item in equipList" v-if="item.id != 1"
            :key="item.id">
            <a-col :span="8" :push="0">
              <div style="width: 100%; text-align: center;" :id="item.id + '_name'">
                {{ item.name }}：
              </div>
            </a-col>
            <a-col :span="16" :pull="0">
              <div style="text-align: start;">
                <a-input-number :default-value="item.demand" @change="(e) => onDemandChange(item.id, e)" :min="0"
                  size="small" :max="100000000" :formatter="value => `${value}kW`"
                  :parser="value => value.replace('kW', '')" style="width: 100px !important;" :disabled="!isAdmin()"/>
              </div>
            </a-col>
          </a-col>
        </a-row>
      </div>


      <!-- 箱变容量限额 -->
      <!-- <DeviceTransformerFactor :equipList="equipList" ref="deviceTransformerFactor"/> -->

      <a-modal v-model="modelShowStrategy" :title="modelTitle" :keyboard="keyboard" :maskClosable="keyboard"
        width="520px">
        <div style="width: 450px;margin: 0px auto;padding: 0 10px 5px 10px; ">
          <a-table :columns="columns" :data-source="strategyTableData" :height="tableHeight" :pagination="false"
            :scroll="{ y: 300 }">
            <template slot="startTime" slot-scope="text, record">
              <a-input type="primary" v-model="record.startTime" size="small" ghost style="width: 80px;">
              </a-input>
            </template>
            <template slot="endTime" slot-scope="text, record">
              <a-input type="primary" v-model="record.endTime" size="small" ghost @change="changeFn(record)"
                style="width: 80px;"></a-input>
            </template>
            <template slot="power" slot-scope="text, record">
              <a-input type="primary" v-model="record.power" size="small" ghost style="width: 80px;">
              </a-input>
            </template>
            <template slot="action" slot-scope="text, record">
              <a-button type="primary" @click="del_msg_fn(record)" size="small" ghost>删除</a-button>
            </template>
          </a-table>
        </div>
        <a-form-model layout="inline" :model="form">
          <a-form-model-item label="放电下限" label-width="80px" style="margin:auto;" prop="dischargeLowerLimit">
            <a-col :span="22">
              <a-input v-model="form.dischargeLowerLimit" placeholder="放电下限" size="small"
                style="width: 351px !important;"></a-input>
            </a-col>
            <span style="color: rgba(255, 255, 255, 0.65);"> %</span>
          </a-form-model-item>
          <a-form-model-item label="放电上限" label-width="80px" style="margin:auto;  margin-top: 15px;"
            prop="dischargeUpperLimit">
            <a-col :span="22">
              <a-input v-model="form.dischargeUpperLimit" placeholder="放电上限" size="small"
                style="width: 351px !important;"></a-input>
            </a-col>
            <span style="color: rgba(255, 255, 255, 0.65);"> %</span>
          </a-form-model-item>
          <a-form-model-item label="名 称" label-width="80px" style="margin:auto;  margin-top: 15px;" prop="name">
            <a-input v-model="form.name" placeholder="策略名称" size="small" style="width: 351px !important;">
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="描 述" label-width="80px" style="margin:auto;  margin-top: 15px;" prop="description">
            <a-input v-model="form.description" placeholder="策略描述" size="small" style="width: 351px !important;">
            </a-input>
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div style="width: 160px; margin: 15px 164px;">
            <a-button type="primary" @click="addFn">
              增加
            </a-button>
            <a-button type="primary" @click="okFn('form')">
              提交
            </a-button>
          </div>
        </template>
      </a-modal>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import DeviceTransformerFactor from './DeviceTransformerFactor.vue'
import { isAdmin } from '../../whiteList';
export default {
  components: {
    DeviceTransformerFactor
  },
  data() {
    return {
      loading: false,
      equipList: [],
      tableHeight: '550',
      columns: [{
        title: '起始时间',
        dataIndex: 'startTime',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'startTime'
        },
      },
      {
        title: '结束时间',
        dataIndex: 'endTime',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'endTime'
        },
      },
      {
        title: '功率',
        dataIndex: 'power',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'power'
        },
      },
      {
        title: '操作',
        key: 'action',
        width: 90,
        align: 'center',
        scopedSlots: {
          customRender: 'action'
        },
      },

      ],
      form: {
        id: null,
        description: "",
        dischargeLowerLimit: null,
        dischargeUpperLimit: null,
        name: '',
        details: []
      },
      strategyTableData: [],
      modelTitle: '',
      modelShowStrategy: false,
      strategy: null,
      strategyType: '2',
      updatePlanDate: null,
      dateFormat: 'YYYY-MM-DD',
      datePickerLocale: datePickerLocale,
      keyboard: false,
      dialogFormVisible: false,
      timerFlag: false,
      dateFlag: 0,
      actionVisible: false,
      dischargeLowerLimit: 0,
      dischargeUpperLimit: 100,
      animated: false,
      tabId: 1,
      yearNum: new Date().getFullYear(),
      monthNum: new Date().getMonth(),
      dateNum: new Date().getDate(),
      selectDayNum: null,
      selectDayLi: null,
      optimalStrategyId: localStorage.getItem("optimalStrategyId"),
      strategyId: localStorage.getItem("strategyId"),
      restModeType: localStorage.getItem("restModeType"),
      holidays: [],
      shutdownSchedule: [],
      rest: [],
      data: [],
      restMode: {
        stationId: localStorage.getItem('stationId'),
        date: new Date(),
        operatingMode: 2
      },
      usingStrategyId: '-1',
      strategy_timer: null,
      planStrategyChart: null,
      strategyChart: null,
      totalDemandValue: 0,
    }
  },
  mounted() {
    var this_ = this;
    this.updatePlanDate = moment(this.restMode.date, 'YYYY-MM-DD'),
      this.getHolidays(1);
    this.list(1);
    this_.listeLinkDemand();
  },
  methods: {
    isAdmin() {
      return isAdmin()
    },
    onDemandChange(id, e) {
      var this_ = this;
      this_.equipList.map(function (item) {
        if (id === item.id) {
          item.demand = e;
        }
      });
    },
    listeLinkDemand() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/elink/demand/' + 1 + "/" + localStorage.getItem('stationId'),
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === '0') {
          this_.totalDemandValue = 0;
          this_.equipList = d.data.data;
          this_.equipList.map(function (item) {
            this_.totalDemandValue += item.demand;
          });
          console.log(this_.equipList);
        } else {
          this_.$message.error('请求出错');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    setLinkDemand() {
      var this_ = this;
      // var clamp = (num) =>  Math.min(1, Math.max(0, num))
      // var factor = this.$refs.deviceTransformerFactor.inputValue.map(clamp).join(',')
      
      var tdemand = 0;
      var data = {
        'name': 'demand',
        'ids': '',
        'datas': '',
        // 'transformerFactor': factor
      };

      if (this_.equipList.length > 1) {
        this_.equipList.map(function (item) {
          tdemand += item.demand;
          data.ids += item.id + ',';
          data.datas += item.demand + ',';
        });
        data.ids = data.ids.substring(0, data.ids.length - 1);
        data.datas = data.datas.substring(0, data.datas.length - 1);
        if (tdemand != this_.totalDemandValue) {
          this_.$message.warning('所有eLink设置的需量不等于总');
          return;
        }
      } else {
        data.ids += this_.equipList[0].id;
        data.datas += this_.totalDemandValue;
      }
      axios({
        method: 'put',
        url: '/emind/config/equipment/elink/demand',
        data: data
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === '0') {
          this_.$message.success('需量设置命令下发成功');
        } else {
          this_.$message.warning('需量设置命令下发失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    cacheRestes() {
      axios({
        method: 'post',
        url: '/emind/real/data/cache/restes',
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === '0') {
          this_.$message.success('服务器更新运行计划缓存列表成功');
        } else {
          this_.$message.warning('服务器更新运行计划缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    cacheStations() {
      axios({
        method: 'post',
        url: '/emind/real/data/cache/stations',
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === '0') {
          this_.$message.success('服务器更新设备缓存列表成功');
        } else {
          this_.$message.warning('服务器更新设备缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    cacheStrategys() {
      var this_ = this;
      axios({
        method: 'post',
        url: '/emind/real/data/cache/stations',
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === '0') {
          this_.$message.success('服务器更新策略缓存列表成功');
        } else {
          this_.$message.warning('服务器更新策略缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    changeFn(record) {
      var n = record.key - 1;
      if (this.strategyTableData[this.strategyTableData.length - 1].endTime != '24:00') {
        var obj = {
          startTime: this.strategyTableData[this.strategyTableData.length - 1].endTime,
          endTime: '24:00',
          power: 0,
          if_change: "none"
        };
        obj.key = this.strategyTableData.length + 1;
        this.strategyTableData.push(obj);
      } else {
        this.strategyTableData[n + 1].startTime == this.strategyTableData[n].endTime;
      }
      if (this.strategyTableData.length == 2) {
        if (n == 0) {
          if (this.strategyTableData[n].endTime != this.strategyTableData[n + 1].startTime) {
            this.strategyTableData[n + 1].startTime = this.strategyTableData[n].endTime;
          }
        } else {
          if (this.strategyTableData[n].startTime != this.strategyTableData[n - 1].endTime) {
            this.strategyTableData[n - 1].endTime == this.strategyTableData[n].startTime;
          }
        }

      } else if (this.strategyTableData.length > 2) {
        if (n == 0) {
          if (this.strategyTableData[n].endTime != this.strategyTableData[n + 1].startTime) {
            this.strategyTableData[n + 1].startTime = this.strategyTableData[n].endTime;
          }
        } else if (n > 0 && n < this.strategyTableData.length - 1) {
          if (this.strategyTableData[n].endTime != this.strategyTableData[n + 1].startTime) {
            this.strategyTableData[n + 1].startTime = this.strategyTableData[n].endTime;
          }
          if (this.strategyTableData[n].startTime != this.strategyTableData[n - 1].endTime) {
            this.strategyTableData[n - 1].endTime = this.strategyTableData[n].startTime;
          }
        } else {
          if (this.strategyTableData[n].startTime != this.strategyTableData[n - 1].endTime) {
            this.strategyTableData[n - 1].endTime = this.strategyTableData[n].startTime;
          }
        }
      }
    },
    addFn() {
      var this_ = this;
      var obj = {
        startTime: '00:00',
        endTime: '24:00',
        power: 0,
        if_change: "none"
      };
      obj.key = this_.strategyTableData.length + 1;
      this_.strategyTableData.push(obj);
    },
    okFn(formName) {
      var this_ = this;
      if (this_.form.name.length < 1) {
        this_.$message.warning('请添加策略名称');
        return;
      }
      var reg = new RegExp("^(\\d|[1-9]\\d|100)$");
      if (!reg.test(this_.form.dischargeLowerLimit) && !reg.test(this_.form.dischargeUpperLimit)) {
        this_.$message.warning('放电下限、放电上限必须是0~100的整数，请正确填写');
      }
      if (this_.strategyTableData.length < 1) {
        this_.$message.warning('请添加策略规则');
        return;
      } else if (this_.strategyTableData.length == 1) {
        if (this_.strategyTableData[0].startTime === "00:00" && (this_.strategyTableData[this_.strategyTableData.length - 1].endTime === "24:00")) { } else {
          this_.$message.warning('策略规则需要包含全天24小时,请填写完整之后再提交');
          return;
        }
      }
      this_.form.dischargeLowerLimit = parseInt(this_.form.dischargeLowerLimit);
      this_.form.dischargeUpperLimit = parseInt(this_.form.dischargeUpperLimit)
      this_.form.details = [];
      var endTime = null;
      for (var i = 0; i < this_.strategyTableData.length; i++) {
        if (i > 0 && endTime != this_.strategyTableData[i].startTime) {
          break;
        }
        endTime = this_.strategyTableData[i].endTime;
        var obj = {
          'startTime': this_.strategyTableData[i].startTime,
          'endTime': this_.strategyTableData[i].endTime,
          'power': parseFloat(this_.strategyTableData[i].power),
        };
        obj.startTime = obj.startTime + ":00";
        obj.endTime = obj.endTime + ":00";
        this_.form.details.push(obj);
        if (endTime === '00:00') {
          break;
        }
      }
      if (endTime != '24:00') {
        this_.$message.warning('策略规则需要包含全天24小时,请填写完整之后再提交');
        return;
      }
      var data = this_.form;
      data.stationId = localStorage.getItem('stationId');
      data.details[data.details.length - 1].endTime = '23:59:59';
      if (this_.ifAdd_ == 1) {
        data.isDefault = false;
        axios({
          method: 'post',
          url: '/emind/config/strategy/creation/' + this_.strategyType,
          data: data
        }).then((d) => {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () { });
            return;
          }
          if (d.data.code === "0") {
            obj.id = d.message;
            this_.$message.success("策略添加成功");
            if (this_.strategyType === '2') {
              this_.strategyId = parseInt(d.data.message);
              localStorage.setItem("strategyId", this_.strategyId);
            } else if (this_.strategyType === '1') {
              this_.optimalStrategyId = parseInt(d.data.message);
              localStorage.setItem("optimalStrategyId", this_.optimalStrategyId);
            }
            this_.form.id = null;
            this_.form.description = null;
            this_.form.dischargeLowerLimit = null;
            this_.form.dischargeUpperLimit = null;
            this_.form.name = null;
            this_.form.details = [];
            this_.cacheStrategys();
            this_.cacheStations();
            setTimeout(() => {
              this_.tabsChange(2);
              this_.modelShowStrategy = false;
            }, 500);
          } else if (d.data.code === '401') {
            this_.modelShowStrategy = false;
            this_.$message.warning('当前账户没有添加策略的操作权限');
          } else {
            this_.$message.error(d.data.message, 2, function () { });
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () { });
            return;
          }
        })
      } else {
        data.id = this_.strategy.id;
        axios({
          method: 'put',
          url: '/emind/config/strategy/modification',
          data: data
        }).then((d) => {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () { });
            return;
          }
          if (d.data.code === "0") {
            this_.$message.success("策略修改成功");
            this_.form.id = null;
            this_.form.description = null;
            this_.form.dischargeLowerLimit = null;
            this_.form.dischargeUpperLimit = null;
            this_.form.name = null;
            this_.form.details = [];
            this_.cacheStrategys();
            setTimeout(() => {
              this_.tabsChange(2);
              this_.modelShowStrategy = false;
            }, 500);
          } else if (d.data.code === '401') {
            this_.modelShowStrategy = false;
            this_.$message.warning('当前账户没有修改策略的操作权限');
          } else {
            this_.$message.error("策略修改失败", 2, function () { });
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () { });
            return;
          }
        })
      }
      setTimeout(() => {
        this_.modelShowStrategy = false;
      }, 50);
    },
    del_msg_fn(record) {
      var n = record.key - 1;
      var this_ = this;
      if (this_.strategyTableData.length < 2) {
        this_.strategyTableData[0].startTime = '00:00';
        this_.strategyTableData[0].endTime = '24:00';
        this_.strategyTableData[0].power = 0;
        this_.strategyTableData[0].if_change = "none";
      } else {
        this_.strategyTableData.splice(n, 1);
      }
      if (n == 0) {
        this_.strategyTableData[0].startTime = '00:00';
      } else if (n == this_.strategyTableData.length) {
        this_.strategyTableData[this_.strategyTableData.length - 1].endTime = '24:00';
      } else {
        this_.strategyTableData[n].startTime = this_.strategyTableData[n - 1].endTime;
      }
      for (var i = 0; i < this_.strategyTableData.length; i++) {
        this_.strategyTableData[i].key = i + 1;
      }
    },
    createFn() {
      var this_ = this;
      if (this_.strategy == null || typeof (this_.strategy.details) === 'undefined') {
        this_.form.id = null;
        this_.strategyTableData = [];
        this_.form.description = '';
        this_.form.dischargeLowerLimit = 0;
        this_.form.dischargeUpperLimit = 100;
        this_.form.details = [];
        this_.modelShowStrategy = true;
        this_.ifAdd_ = 1;
        this_.modelTitle = "创建策略";
        this_.form.name = '';
        var obj = {
          startTime: "00:00",
          endTime: "24:00",
          power: 0,
          if_change: "none"
        };
        obj.key = this_.strategyTableData.length + 1;
        this_.strategyTableData.push(obj);
      } else {
        this_.$message.info('该策略已经存在不能创建，请点击修改按钮！', 2, function () { });
        return;
      }
    },
    updateFn() {
      var this_ = this;
      if (this_.strategy == null || typeof (this_.strategy.details) === 'undefined') {
        this_.$message.info('请先创建策略！', 2, function () { });
        return;
      } else {
        this_.strategyTableData = [];
        this_.form.id = this_.strategy.id;
        this_.form.description = this_.strategy.description;
        this_.form.dischargeLowerLimit = this_.strategy.dischargeLowerLimit;
        this_.form.dischargeUpperLimit = this_.strategy.dischargeUpperLimit;
        this_.form.details = [];
        this_.modelShowStrategy = true;
        this_.ifAdd_ = 2;
        this_.modelTitle = "修改策略";
        this_.form.name = this_.strategy.name;
        for (var i = 0; i < this_.strategy.details.length; i++) {
          var obj = {
            startTime: this_.strategy.details[i].startTime.substring(0, 5),
            endTime: this_.strategy.details[i].endTime.substring(0, 5),
            power: this_.strategy.details[i].power,
            if_change: "none"
          };
          obj.key = i + 1;
          this_.strategyTableData.push(obj);
        }
        this_.strategyTableData[this_.strategyTableData.length - 1].endTime = '24:00';
      }
    },
    delFn() {
      var this_ = this;
      if (this_.strategy == null || typeof (this_.strategy.details) === 'undefined') {
        this_.$message.info('请先创建策略！', 2, function () { });
        return;
      } else {
        this_.$confirm({
          title: "删除策略",
          content: '此操作将永久删除该策略, 是否继续?',
          okText: '确认',
          cancelText: '取消',
          onOk() {
            axios.delete('/emind/config/strategy/deletion/' + this_.usingStrategyId).then((d) => {
              if (!d) {
                this_.$message.error('登录超时，请重新登录', 2, function () { });
                return;
              }
              if (d.data.code === "0") {
                setTimeout(() => {
                  this_.tabsChange(2);
                  this_.$message.info('删除成功', 2, function () { });
                  this_.modelShowStrategy = false;
                }, 500);
              } else if (d.data.code === '401') {
                this_.$message.warning('当前账户没有删除策略的操作权限');
              } else {
                this_.$message.error(d.data.message, 2, function () { });
              }
            })
          },
        });
      }
    },
    strategyTypeChange() {
      var this_ = this;
      this.data = [];
      var now = new Date();
      this_.strategy = null;
      if (this.strategyType == "2") {
        this.usingStrategyId = this.strategyId;
      } else if (this.strategyType == "1") {
        this.usingStrategyId = this.optimalStrategyId;
      } else {
        this.data.push([
          now.Format('yyyy-MM-dd ') + "00:00:00",
          now.Format('yyyy-MM-dd ') + "23:59:59",
          0,
        ]);
      }
      if (this_.data.length != 1) {
        if (this_.strategyList && this_.strategyList.length > 0) {
          this_.strategyList.map(function (item) {
            if (item.id == this_.usingStrategyId) {
              this_.strategy = item;
              item.details.map(function (x) {
                this_.data.push([
                  now.Format('yyyy-MM-dd ') + x.startTime,
                  now.Format('yyyy-MM-dd ') + x.endTime,
                  x.power,
                ]);
              });
            }
          });
        }
      }
      this_.parse();
      var newOp = this.strategyChart.getOption();
      newOp.tooltip = {},
        newOp.series = {
          type: 'custom',
          renderItem: this_.renderItem,
          label: {
            normal: {
              show: true,
              position: 'top'
            }
          },
          dimensions: ['开始时间 ', '结束时间 ', '功率（kW） '],
          encode: {
            x: [0, 1],
            y: 2,
            tooltip: [0, 1, 2],
            itemName: 4
          },
          data: this_.data
        };
      newOp.xAxis = {
        scale: true,
        type: 'time',
        splitNumber: 12,
        splitLine: {
          lineStyle: {
            color: '#7C868D',
          }
        },
        axisLine: {
          lineStyle: {
            color: '#7C868D',
          }
        },
        min: now.Format("yyyy-MM-dd ") + "00:00:00",
        max: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).Format("yyyy-MM-dd ") + "00:00:00",
        axisLabel: {
          formatter: function (params, index) {
            var currentYear = moment().format("YYYY");
            var currentMonth = moment().format("MM");
            var currentDay = moment().format("DD");
            var thisYear = moment(Number(params)).format("YYYY");
            var thisMonth = moment(Number(params)).format("MM");
            var thisDay = moment(Number(params)).format("DD");
            var hourAndMinite = moment(Number(params)).format("HH:mm");
            return hourAndMinite
          }
        }
      };
      newOp.yAxis = {
        show: true,
        axisLine: {
          lineStyle: {
            color: '#7C868D',
          }
        },
        splitLine: {
          lineStyle: {
            color: '#7C868D',
          }
        }
      },
        newOp.grid = {
          top: 50,
          left: 40,
          bottom: 40,
          right: 20
        };
      newOp.title = {
        textStyle: {
          fontFamily: "微软雅黑",
          color: '#E2E2E2'
        },
        text: (this_.strategyType === '2' ? '工作日' : this_.strategyType === '1' ? '节假日' : '检修') + '策略 ',
        x: 'center'
      };
      this.strategyChart.setOption(newOp);
    },
    updatePlan() {
      var this_ = this;
      var data = this_.restMode;
      data.date = this_.restMode.date.Format("yyyy-MM-dd HH:mm:ss");
      axios({
        method: 'put',
        url: '/emind/config/production/plan/rest/mode/modification',
        data: data
      }).then((d) => {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        this.timerFlag = false;
        this_.dialogFormVisible = false;
        if (d.data.code === "0") {
          this.$message.success('生产计划修改成功');
          setTimeout(() => {
            this_.cacheRestes();
            this_.getHolidays(1);
          }, 200);
        } else if (d.data.code === '401') {
          this_.$message.warning('当前账户没有修改运行计划的操作权限');
        } else {
          this_.$message.error("生产计划修改失败", 2, function () { });
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      })
    },
    restModeTypeChange(restModeType) {
      var this_ = this;
      this.actionVisible = false;
      this_.$confirm({
        title: "修改策略模式",
        content: '请确认是否修改为' + localStorage.getItem('stationName') + (restModeType == 2 ? '双休策略模式' : '单休策略模式'),
        okText: '确认',
        cancelText: '取消',
        onOk() {
          axios({
            method: 'put',
            url: '/emind/config/station/rest/mode/type/' + localStorage.getItem('stationId') + "/" + restModeType
          }).then(function (d) { //
            if (!d) {
              this_.$message.error('登录超时，请重新登录', 2, function () { });
              return;
            }
            if (d.data.code === '0') {
              this_.$message.success(restModeType == 2 ? "双休策略模式修改成功！" : "单休策略模式修改成功！");
              localStorage.setItem("restModeType", restModeType);
              this_.cacheStations();
              this_.getHolidays(1);
            } else if (d.data.code === '401') {
              this_.$message.warning('当前账户没有修改策略模式的操作权限');
            } else {
              this_.$message.error(restModeType == 2 ? "双休策略模式修改失败！" : "单休策略模式修改失败！", 2, function () { });
            }
          }).catch(function (error) {
            if (error.message != '') {
              this_.$message.error(error.message, 2, function () { });
              return;
            }
          });
        },
      });
    },
    previous(type) {
      let riliContent = document.getElementById('riliContent');
      riliContent.innerHTML = "";
      this.monthNum--;
      if (this.monthNum < 0) {
        this.monthNum = 11;
        this.yearNum -= 1;
      }
      this.getHolidays(1);
    },
    next(type) {
      let riliContent = document.getElementById('riliContent');
      riliContent.innerHTML = "";
      this.monthNum++;
      if (this.monthNum > 11) {
        this.monthNum = 0;
        this.yearNum += 1;
      }
      this.getHolidays(1);
    },
    today() {
      this.selectDayNum = this.dateNum;
      let riliContent = document.getElementById('riliContent');
      riliContent.innerHTML = "";
      this.monthNum = new Date().getMonth();
      this.getHolidays(1);
    },
    tabsChange(activeKey) {
      var this_ = this;
      this.tabId = activeKey;
      this.list(0);
      if (activeKey == 1) {
        //充放电计划界面
        setTimeout(() => {
          var date = this_.restMode.date;
          this_.usingStrategyId = -1;
          if (this_.rest.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '2';
            this_.usingStrategyId = this_.strategyId;
          } else if (this_.holidays.includes(date.Format("yyyy-MM-dd"))) {
            this_.usingStrategyId = this_.optimalStrategyId;
            this_.restMode.operatingMode = '1';
          } else if (this_.shutdownSchedule.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '0';
            this_.usingStrategyId = -1;
          } else {
            if (parseInt(this_.restModeType) == 2) {
              //双休
              if (date.getDay() == 6) {
                this_.usingStrategyId = this_.optimalStrategyId;
              } else {
                this_.usingStrategyId = this_.strategyId;
              }

            } else if (parseInt(this_.restModeType) == 1) {
              //单休
              if (date.getDay() == 0) {
                this_.usingStrategyId = this_.optimalStrategyId;
              } else {
                this_.usingStrategyId = this_.strategyId;
              }
            }
          }
          this_.data = [];
          this_.strategy = null;
          if (this_.strategyList && this_.strategyList.length > 0) {
            this_.strategyList.map(function (item) {
              if (item.id == this_.usingStrategyId) {
                this_.strategy = item;
                item.details.map(function (x) {
                  this_.data.push([
                    date.Format('yyyy-MM-dd ') + x.startTime,
                    date.Format('yyyy-MM-dd ') + x.endTime,
                    x.power,
                  ]);
                });
              }
            });
          }
          if (this_.usingStrategyId == -1) {
            this_.data.push([
              now.Format('yyyy-MM-dd ') + "00:00:00",
              now.Format('yyyy-MM-dd ') + "23:59:59",
              0,
            ]);
          }
          this_.planStrategyChart = this_.$echarts.init(document.getElementById('plan_strategy_chart'));
          var newOp = {};
          var title = '';
          if (this_.data.length > 0) {
            title = '(' + date.Format("yyyy-MM-dd") + ')  ' + (this_.restMode.operatingMode == 2 ? '单充单放策略' : (this_.restMode.operatingMode === '1' ? '一充一放策略' : '停机策略'));
          }
          newOp.title = {
            textStyle: {
              fontFamily: "微软雅黑",
              color: '#E2E2E2'
            },
            text: '运行策略 ' + title,
            //							subtext: this_.strategy == null ? '放电下限：0%' + '\t\t\t\t\t\t\t' + '充电上限：100%' : '放电下限：' + this_.strategy.dischargeLowerLimit + '%' + '\t\t\t\t\t\t\t' + '充电上限：' + this_.strategy.dischargeUpperLimit + '%',
            //							subtextStyle: {
            //								fontFamily: "微软雅黑",
            //								color: '#fff',
            //								fontSize: 14,
            //							},
            x: 'center'
          };
          this_.parse();
          newOp.tooltip = {},
            newOp.series = {
              type: 'custom',
              renderItem: this_.renderItem,
              label: {
                normal: {
                  show: true,
                  position: 'top'
                }
              },
              dimensions: ['开始时间 ', '结束时间 ', '功率（kW） '],
              encode: {
                x: [0, 1],
                y: 2,
                tooltip: [0, 1, 2],
                itemName: 4
              },
              data: this_.data
            };
          newOp.xAxis = {
            scale: true,
            type: 'time',
            splitNumber: 12,
            splitLine: {
              lineStyle: {
                color: '#7C868D',
              }
            },
            axisLine: {
              lineStyle: {
                color: '#7C868D',
              }
            },
            min: date.Format("yyyy-MM-dd ") + "00:00:00",
            max: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).Format("yyyy-MM-dd ") + "00:00:00",
            axisLabel: {
              formatter: function (params, index) {
                var currentYear = moment().format("YYYY");
                var currentMonth = moment().format("MM");
                var currentDay = moment().format("DD");
                var thisYear = moment(Number(params)).format("YYYY");
                var thisMonth = moment(Number(params)).format("MM");
                var thisDay = moment(Number(params)).format("DD");
                var hourAndMinite = moment(Number(params)).format("HH:mm");
                return hourAndMinite
              }
            }
          };
          newOp.yAxis = {
            show: true,
            axisLine: {
              lineStyle: {
                color: '#7C868D',
              }
            },
            splitLine: {
              lineStyle: {
                color: '#7C868D',
              }
            }
          },
            newOp.grid = {
              top: 90,
              left: 80,
              bottom: 50,
              right: 25
            };
          this_.planStrategyChart.setOption(newOp);
        }, 400);
        setTimeout(() => {
          window.onresize = function () {
            this_.planStrategyChart.resize();
          }
        }, 200)
      } else {
        //策略管理界面
        setTimeout(() => {
          this_.usingStrategyId = -1;
          this_.data = [];
          var now = new Date();
          if (this_.strategyType === "2") {
            this_.usingStrategyId = this_.strategyId;
          } else if (this_.strategyType === "1") {
            this_.usingStrategyId = this_.optimalStrategyId;
          }
          this_.dischargeLowerLimit = 0;
          this_.dischargeUpperLimit = 100;
          if (this_.strategyList && this_.strategyList.length > 0) {
            this_.strategyList.map(function (item) {
              if (item.id == this_.usingStrategyId) {
                this_.strategy = item;
                item.details.map(function (x) {
                  this_.data.push([
                    now.Format('yyyy-MM-dd ') + x.startTime,
                    now.Format('yyyy-MM-dd ') + x.endTime,
                    x.power,
                  ]);
                });
                this_.dischargeLowerLimit = item.dischargeLowerLimit;
                this_.dischargeUpperLimit = item.dischargeUpperLimit;
              }
            });
          }
          if (this_.usingStrategyId == -1) {
            this_.data.push([
              now.Format('yyyy-MM-dd ') + "00:00:00",
              now.Format('yyyy-MM-dd ') + "23:59:59",
              0,
            ]);
          }
          this_.parse();
          this_.strategyChart = this_.$echarts.init(document.getElementById('strategy_chart'));
          var newOp = {};
          newOp.tooltip = {},
            newOp.series = {
              type: 'custom',
              renderItem: this_.renderItem,
              label: {
                normal: {
                  show: true,
                  position: 'top'
                }
              },
              dimensions: ['开始时间 ', '结束时间 ', '功率（kW） '],
              encode: {
                x: [0, 1],
                y: 2,
                tooltip: [0, 1, 2],
                itemName: 4
              },
              data: this_.data
            };
          newOp.xAxis = {
            scale: true,
            type: 'time',
            splitNumber: 12,
            splitLine: {
              lineStyle: {
                color: '#7C868D',
              }
            },
            axisLine: {
              lineStyle: {
                color: '#7C868D',
              }
            },
            min: now.Format("yyyy-MM-dd ") + "00:00:00",
            max: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).Format("yyyy-MM-dd ") + "00:00:00",
            axisLabel: {
              formatter: function (params, index) {
                var currentYear = moment().format("YYYY");
                var currentMonth = moment().format("MM");
                var currentDay = moment().format("DD");
                var thisYear = moment(Number(params)).format("YYYY");
                var thisMonth = moment(Number(params)).format("MM");
                var thisDay = moment(Number(params)).format("DD");
                var hourAndMinite = moment(Number(params)).format("HH:mm");
                return hourAndMinite
              }
            }
          };
          newOp.yAxis = {
            show: true,
            axisLine: {
              lineStyle: {
                color: '#7C868D',
              }
            },
            splitLine: {
              lineStyle: {
                color: '#7C868D',
              }
            }
          },
            newOp.grid = {
              top: 50,
              left: 40,
              bottom: 40,
              right: 20
            };
          newOp.title = {
            textStyle: {
              fontFamily: "微软雅黑",
              color: '#E2E2E2'
            },
            text: (this_.strategyType === '2' ? '工作日' : this_.strategyType === '1' ? '节假日' : '检修') + '策略 ',
            x: 'center'
          };
          this_.strategyChart.setOption(newOp);
          this_.planStrategyChart.dispose();
        }, 400);
        setTimeout(() => {
          window.onresize = function () {
            this_.strategyChart.resize();
          }
        }, 200);
      }
    },
    getHolidays(type) {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/production/plan/holidays/' + localStorage.getItem('stationId') + "/" + this_.yearNum,
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === '0') {
          JSON.parse(d.data.data.holiday).map(function (item) {
            item.list.map(function (x) {
              if (x.status == 1 && !this_.holidays.includes(x.date)) {
                this_.holidays.push(x.date);
              } else if (x.status == 2 && !this_.rest.includes(x.date)) {
                this_.rest.push(x.date);
              }
            });
          });
          var index;
          var date;
          JSON.parse(d.data.data.productionPlan).map(function (item) {
            date = new Date(item.date).Format("yyyy-MM-dd");
            if (item.operatingMode == 1) {
              if (!this_.holidays.includes(date)) {
                index = this_.rest.indexOf(date);
                if (index > -1) {
                  this_.rest.splice(index, 1);
                }
                index = this_.shutdownSchedule.indexOf(date);
                if (index > -1) {
                  this_.shutdownSchedule.splice(index, 1);
                }
                this_.holidays.push(date);
              }
            } else if (item.operatingMode == 2) {
              if (!this_.rest.includes(date)) {
                index = this_.holidays.indexOf(date);
                if (index > -1) {
                  this_.holidays.splice(index, 1);
                }
                index = this_.shutdownSchedule.indexOf(date);
                if (index > -1) {
                  this_.shutdownSchedule.splice(index, 1);
                }
                this_.rest.push(date);
              }
            } else if (item.operatingMode == 0) {
              index = this_.holidays.indexOf(date);
              if (index > -1) {
                this_.holidays.splice(index, 1);
              }
              index = this_.rest.indexOf(date);
              if (index > -1) {
                this_.rest.splice(index, 1);
              }
              this_.shutdownSchedule.push(date);
            }
          });

        } else {
          this_.$message.error('请求出错', 2, function () { });
        }
        if (type == 1) {
          riliContent.innerHTML = "";
          this_.selectDayNum = this_.selectDayNum == null ? new Date().getDate() : this_.selectDayNum;
          this_.initCalendar(new Date(this_.yearNum, this_.monthNum, this_.selectDayNum), 1);
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    getDom(type, now) {
      var dom = document.createElement("div")
      if (type == 1) {
        dom.style.height = '40%';
        dom.style.width = '100%';
        dom.style.float = 'left';
        dom.style.lineHeight = '40px';
      } else {
        dom.style.height = '20%';
        dom.style.width = '20%';
        dom.style.marginLeft = '40%';
        dom.style.marginTop = '15%';
        dom.style.borderRadius = '100%';
        dom.style.float = 'left';
        if ((this.holidays.includes(now.Format("yyyy-MM-dd")) || this.shutdownSchedule.includes(now.Format("yyyy-MM-dd"))) && !this.rest.includes(now.Format("yyyy-MM-dd"))) {
          if (!this.shutdownSchedule.includes(now.Format("yyyy-MM-dd"))) {
            dom.style.background = "#49AA19";
          } else {
            dom.style.background = "#F26813";
          }
        } else if (this.rest.includes(now.Format("yyyy-MM-dd"))) {
          dom.style.background = "#177DDC";
        } else {
          if (parseInt(this.restModeType) == 1) {
            if (now.getDay() == 0) {
              dom.style.background = "#49AA19";
            } else {
              dom.style.background = "#177DDC";
            }
          } else if (parseInt(this.restModeType) == 2) {
            if (now.getDay() == 0 || now.getDay() == 6) {
              dom.style.background = "#49AA19";
            } else {
              dom.style.background = "#177DDC";
            }
          } else {
            dom.style.background = "#177DDC";
          }
        }
        if (now.Format("yyyy-MM-dd") === new Date().Format("yyyy-MM-dd")) {
          dom.style.background = "#fff";
        }
      }
      return dom;
    },
    initCalendar(days, type) {
      this.restModeType = localStorage.getItem("restModeType");
      var this_ = this;
      let riliContent = document.getElementById('riliContent');
      let showTime = document.getElementById('showTime');
      let previous = document.getElementById('previous');
      let next = document.getElementById('next');

      let newDate = new Date();

      let year = days.getFullYear();
      let month = days.getMonth();

      let lastDay = new Date(year, month + 1, 0);
      let lastDay_date = lastDay.getDate(); //这个月的最后一天
      let firstDay = new Date(year, month - 1, 1);
      let firstDay_day = firstDay.getDay(); //这个月第一天

      //上个月的最后一天

      let pDay = new Date(year, month, 0);
      let last_p_day = pDay.getDate();
      var now;
      let n = pDay.getDay(); //前几个灰色数生成
      this.dateFlag = -1;
      for (let j = 0; j <= n; j++) {
        now = new Date(this_.yearNum, this_.monthNum + this.dateFlag, last_p_day - (n - j));
        let LI = document.createElement("li");
        let text = this.getDom(1);
        let icon = this.getDom(2, now);
        text.innerHTML = now.getDate();
        LI.appendChild(text);
        LI.appendChild(icon);
        LI.style.color = "rgba(255,255,255,0.3)";
        LI.style.cursor = "pointer";
        riliContent.appendChild(LI);
        if (j == 0) {
          LI.style.background = "#000"
        }
        LI.addEventListener("mouseover", function () {
          LI.style.border = "3px #F26813 solid"
          LI.style.height = '58px';
          LI.style.width = '58px';
        });
        LI.addEventListener("mouseout", function () {
          if (this_.selectDayNum == null) {
            if (last_p_day - (n - j) == new Date().getDate()) {
              this_.selectDayNum = last_p_day - (n - j);
              this_.selectDayLi.style.border = "none";
              LI.style.border = "3px #7C868D solid"
              LI.style.height = '58px';
              LI.style.width = '58px';
              this_.selectDayLi = LI;
            } else {
              LI.style.border = "none";
              LI.style.height = '64px';
              LI.style.width = '64px';
            }
          } else {
            if (last_p_day - (n - j) == this_.selectDayNum) {
              this_.selectDayLi.style.border = "none";
              LI.style.border = "3px #7C868D solid"
              this_.selectDayLi = LI;
              LI.style.height = '58px';
              LI.style.width = '58px';
            } else {
              LI.style.border = "none";
              LI.style.height = '64px';
              LI.style.width = '64px';
            }
          }
        });
        LI.addEventListener("click", function () {
          this_.selectDayNum = last_p_day - (n - j);
          this_.selectDayLi.style.border = "none";
          this_.selectDayLi.style.height = '64px';
          this_.selectDayLi.style.width = '64px';
          LI.style.border = "3px #7C868D solid"
          LI.style.height = '58px';
          LI.style.width = '58px';
          this_.selectDayLi = LI;
          this_.dateFlag = -1;
          this_.updateStrategy();
        });
        LI.addEventListener("dblclick", function () {
          this_.timerFlag = true;
          this_.dialogFormVisible = true;
          this_.dateFlag = -1;
          this_.selectDayNum = last_p_days + j;
          this_.selectDayLi = LI;
          var operatingMode = '2';
          var date = new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum);
          this_.updatePlanDate = moment(date, 'YYYY-MM-DD');
          this_.restMode.date = date;
          this_.restMode.operatingMode = '2';
          this_.usingStrategyId = this_.strategyId;
          if (this_.rest.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '2';
            this_.usingStrategyId = this_.strategyId;
          } else if (this_.holidays.includes(date.Format("yyyy-MM-dd"))) {
            this_.usingStrategyId = this_.optimalStrategyId;
            this_.restMode.operatingMode = '1';
          } else if (this_.shutdownSchedule.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '0';
            this_.usingStrategyId = -1;
          } else {
            if (parseInt(this_.restModeType) == 1) {
              if (date.getDay() == 0) {
                this_.restMode.operatingMode = '1';
                this_.usingStrategyId = this_.optimalStrategyId;
              }
            }
            if (parseInt(this_.restModeType) == 2) {
              if (date.getDay() == 0 || date.getDay() == 6) {
                this_.restMode.operatingMode = '1';
                this_.usingStrategyId = this_.optimalStrategyId;
              }
            }
          }

          this_.data = [];
          this_.strategy = null;
          if (this_.usingStrategyId == -1) {
            this_.data.push([
              date.Format('yyyy-MM-dd ') + "00:00:00",
              date.Format('yyyy-MM-dd ') + "23:59:59",
              0,
            ]);
          } else {
            if (this_.strategyList.length > 0) {
              this_.strategyList.map(function (item) {
                if (item.id == this_.usingStrategyId) {
                  this_.strategy = item;
                  item.details.map(function (x) {
                    this_.data.push([
                      date.Format('yyyy-MM-dd ') + x.startTime,
                      date.Format('yyyy-MM-dd ') + x.endTime,
                      x.power,
                    ]);
                  });
                }
              });
            }
          }
          this_.reflush();
        });
      }
      if (this_.selectDayNum > lastDay_date) {
        this_.selectDayNum = lastDay_date;
      }
      //本月数生成
      this_.restMode = {
        stationId: localStorage.getItem('stationId'),
        date: new Date(this_.yearNum, this_.monthNum, this_.selectDayNum),
        operatingMode: '2'
      };
      this.dateFlag = 0;
      for (let i = 1; i <= lastDay_date; i++) {
        now = new Date(this_.yearNum, this_.monthNum + this.dateFlag, i);
        let LI = document.createElement("li");
        let text = this.getDom(1);
        let icon = this.getDom(2, now);
        text.innerHTML = i;
        LI.appendChild(text);
        LI.appendChild(icon);
        LI.style.cursor = "pointer";
        riliContent.appendChild(LI);
        if (now.getDay() == 0 || now.getDay() == 6) {
          LI.style.background = "#000"
        }
        if (this_.yearNum === newDate.getFullYear() && this_.monthNum === newDate.getMonth() && newDate.getDate() === i) {
          LI.style.background = "#177DDC";
          LI.style.borderRadius = "2px";
        }
        LI.addEventListener("mouseover", function () {
          LI.style.border = "3px #F26813 solid"
          LI.style.height = '58px';
          LI.style.width = '58px';
        });
        LI.addEventListener("mouseout", function () {
          if (this_.selectDayNum == null) {
            if (i == new Date().getDate()) {
              this_.selectDayNum = i;
              this_.selectDayLi.style.border = "none";
              LI.style.border = "3px #7C868D solid"
              LI.style.height = '58px';
              LI.style.width = '58px';
              this_.selectDayLi = LI;
            } else {
              LI.style.border = "none";
              LI.style.height = '64px';
              LI.style.width = '64px';
            }
          } else {
            if (i == this_.selectDayNum) {
              this_.selectDayLi.style.border = "none";
              LI.style.border = "3px #7C868D solid"
              this_.selectDayLi = LI;
              LI.style.height = '58px';
              LI.style.width = '58px';
            } else {
              LI.style.border = "none";
              LI.style.height = '64px';
              LI.style.width = '64px';
            }
          }
        });
        if (this_.selectDayLi == null && i == new Date().getDate()) {
          this_.selectDayLi = LI;
        }
        if (this_.selectDayNum == null) {
          if (i == new Date().getDate()) {
            this_.selectDayNum = i;
            this_.selectDayLi.style.border = "none";
            LI.style.border = "3px #7C868D solid"
            LI.style.height = '58px';
            LI.style.width = '58px';
            this_.selectDayLi = LI;
          }
        } else {
          if (i == this_.selectDayNum) {
            this_.selectDayLi.style.border = "none";
            LI.style.border = "3px #7C868D solid"
            this_.selectDayLi = LI;
            LI.style.height = '58px';
            LI.style.width = '58px';
          }
        }

        LI.addEventListener("dblclick", function () {
          this_.timerFlag = true;
          this_.dialogFormVisible = true;
          this_.dateFlag = 0;
          this_.selectDayNum = i;
          this_.selectDayLi = LI;
          var operatingMode = '2';
          var date = new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum);
          this_.updatePlanDate = moment(date, 'YYYY-MM-DD');
          this_.restMode.date = date;
          this_.restMode.operatingMode = '2';
          this_.usingStrategyId = this_.strategyId;
          if (this_.rest.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '2';
            this_.usingStrategyId = this_.strategyId;
          } else if (this_.holidays.includes(date.Format("yyyy-MM-dd"))) {
            this_.usingStrategyId = this_.optimalStrategyId;
            this_.restMode.operatingMode = '1';
          } else if (this_.shutdownSchedule.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '0';
            this_.usingStrategyId = -1;
          } else {
            if (parseInt(this_.restModeType) == 1) {
              if (date.getDay() == 0) {
                this_.restMode.operatingMode = '1';
                this_.usingStrategyId = this_.optimalStrategyId;
              }
            }
            if (parseInt(this_.restModeType) == 2) {
              if (date.getDay() == 0 || date.getDay() == 6) {
                this_.restMode.operatingMode = '1';
                this_.usingStrategyId = this_.optimalStrategyId;
              }
            }
          }

          this_.data = [];
          this_.strategy = null;
          if (this_.usingStrategyId == -1) {
            this_.data.push([
              date.Format('yyyy-MM-dd ') + "00:00:00",
              date.Format('yyyy-MM-dd ') + "23:59:59",
              0,
            ]);
          } else {
            if (this_.strategyList.length > 0) {
              this_.strategyList.map(function (item) {
                if (item.id == this_.usingStrategyId) {
                  this_.strategy = item;
                  item.details.map(function (x) {
                    this_.data.push([
                      date.Format('yyyy-MM-dd ') + x.startTime,
                      date.Format('yyyy-MM-dd ') + x.endTime,
                      x.power,
                    ]);
                  });
                }
              });
            }
          }
          this_.reflush();
        });
        LI.addEventListener("click", function () {
          this_.selectDayNum = parseInt(LI.childNodes[0].innerHTML);
          this_.selectDayLi.style.border = "none";
          this_.selectDayLi.style.height = '64px';
          this_.selectDayLi.style.width = '64px';
          LI.style.border = "3px #7C868D solid"
          LI.style.height = '58px';
          LI.style.width = '58px';
          this_.selectDayLi = LI;
          this_.dateFlag = 0;
          this_.updateStrategy();
        });
      }
      this.dateFlag = 1;
      for (let m = 1; m < (7 - ((n + lastDay_date) % 7)); m++) {
        let LI = document.createElement("li");
        now = new Date(this_.yearNum, this_.monthNum + this.dateFlag, m);
        let text = this.getDom(1);
        let icon = this.getDom(2, now);
        text.innerHTML = m;
        LI.appendChild(text);
        LI.appendChild(icon);
        LI.style.color = "#ddd";
        LI.style.cursor = "pointer";
        riliContent.appendChild(LI);
        if (now.getDay() == 6) {
          LI.style.background = "#000"
        }
        LI.style.color = "rgba(255,255,255,0.3)";
        LI.addEventListener("mouseover", function () {
          LI.style.border = "3px #F26813 solid"
          LI.style.height = '58px';
          LI.style.width = '58px';
        });
        LI.addEventListener("mouseout", function () {
          if (this_.selectDayNum == null) {
            if (m == new Date().getDate()) {
              this_.selectDayNum = m;
              this_.selectDayLi.style.border = "none";
              LI.style.border = "3px #7C868D solid"
              LI.style.height = '58px';
              LI.style.width = '58px';
              this_.selectDayLi = LI;
            } else {
              LI.style.border = "none";
              LI.style.height = '64px';
              LI.style.width = '64px';
            }
          } else {
            if (m == this_.selectDayNum) {
              this_.selectDayLi.style.border = "none";
              LI.style.border = "3px #7C868D solid"
              this_.selectDayLi = LI;
              LI.style.height = '58px';
              LI.style.width = '58px';
            } else {
              LI.style.border = "none";
              LI.style.height = '64px';
              LI.style.width = '64px';
            }
          }
        });
        LI.addEventListener("click", function () {
          this_.selectDayNum = m;
          this_.selectDayLi.style.border = "none";
          this_.selectDayLi.style.height = '64px';
          this_.selectDayLi.style.width = '64px';
          LI.style.border = "3px #7C868D solid"
          LI.style.height = '58px';
          LI.style.width = '58px';
          this_.selectDayLi = LI;
          this_.dateFlag = 1;
          this_.updateStrategy();
        });
        LI.addEventListener("dblclick", function () {
          this_.timerFlag = true;
          this_.dialogFormVisible = true;
          this_.dateFlag = 1;
          this_.selectDayNum = m;
          this_.selectDayLi = LI;
          var operatingMode = '2';
          var date = new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum);
          this_.restMode.date = date;
          this_.updatePlanDate = moment(date, 'YYYY-MM-DD');
          this_.restMode.operatingMode = '2';
          this_.usingStrategyId = this_.strategyId;
          if (this_.rest.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '2';
            this_.usingStrategyId = this_.strategyId;
          } else if (this_.holidays.includes(date.Format("yyyy-MM-dd"))) {
            this_.usingStrategyId = this_.optimalStrategyId;
            this_.restMode.operatingMode = '1';
          } else if (this_.shutdownSchedule.includes(date.Format("yyyy-MM-dd"))) {
            this_.restMode.operatingMode = '0';
            this_.usingStrategyId = -1;
          } else {
            if (parseInt(this_.restModeType) == 1) {
              if (date.getDay() == 0) {
                this_.restMode.operatingMode = '1';
                this_.usingStrategyId = this_.optimalStrategyId;
              }
            }
            if (parseInt(this_.restModeType) == 2) {
              if (date.getDay() == 0 || date.getDay() == 6) {
                this_.restMode.operatingMode = '1';
                this_.usingStrategyId = this_.optimalStrategyId;
              }
            }
          }

          this_.data = [];
          this_.strategy = null;
          if (this_.usingStrategyId == -1) {
            this_.data.push([
              date.Format('yyyy-MM-dd ') + "00:00:00",
              date.Format('yyyy-MM-dd ') + "23:59:59",
              0,
            ]);
          } else {
            if (this_.strategyList.length > 0) {
              this_.strategyList.map(function (item) {
                if (item.id == this_.usingStrategyId) {
                  this_.strategy = item;
                  item.details.map(function (x) {
                    this_.data.push([
                      date.Format('yyyy-MM-dd ') + x.startTime,
                      date.Format('yyyy-MM-dd ') + x.endTime,
                      x.power,
                    ]);
                  });
                }
              });
            }
          }
          this_.reflush();
        });
      }
      this.dateFlag = 0;
      if (type == 1) {
        if (this_.rest.includes(this_.restMode.date.Format("yyyy-MM-dd"))) {
          this_.restMode.operatingMode = '2';
          this_.usingStrategyId = this_.strategyId;
        } else if (this_.holidays.includes(this_.restMode.date.Format("yyyy-MM-dd"))) {
          this_.usingStrategyId = this_.optimalStrategyId;
          this_.restMode.operatingMode = '1';
        } else if (this_.shutdownSchedule.includes(this_.restMode.date.Format("yyyy-MM-dd"))) {
          this_.restMode.operatingMode = '0';
          this_.usingStrategyId = -1;
        } else {
          if (parseInt(this_.restModeType) == 1) {
            if (this_.restMode.date.getDay() == 0) {
              this_.restMode.operatingMode = '1';
              this_.usingStrategyId = this_.optimalStrategyId;
            } else {
              this_.usingStrategyId = this_.strategyId;
            }
          } else if (parseInt(this_.restModeType) == 2) {
            if (this_.restMode.date.getDay() == 6 || this_.restMode.date.getDay() == 0) {
              this_.restMode.operatingMode = '1';
              this_.usingStrategyId = this_.optimalStrategyId;
            } else {
              this_.usingStrategyId = this_.strategyId;
            }
          } else {
            this_.usingStrategyId = this_.strategyId;
          }
        }

        this_.data = [];
        this_.strategy = null;
        if (this_.usingStrategyId == -1) {
          this_.data.push([
            this_.restMode.date.Format('yyyy-MM-dd ') + "00:00:00",
            this_.restMode.date.Format('yyyy-MM-dd ') + "23:59:59",
            0
          ]);
        } else {
          if (this_.strategyList && this_.strategyList.length > 0) {
            this_.strategyList.map(function (item) {
              if (item.id == this_.usingStrategyId) {
                this_.strategy = item;
                item.details.map(function (x) {
                  this_.data.push([
                    this_.restMode.date.Format('yyyy-MM-dd ') + x.startTime,
                    this_.restMode.date.Format('yyyy-MM-dd ') + x.endTime,
                    x.power
                  ]);
                });
              }
            });
          }
        }
        this_.reflush();
      }
    },
    reflush() {
      var this_ = this;
      if (this_.planStrategyChart == null) {
        return;
      }
      var newOp = this_.planStrategyChart.getOption();
      this_.parse();
      newOp.series = {
        type: 'custom',
        renderItem: this_.renderItem,
        label: {
          normal: {
            show: true,
            position: 'top'
          }
        },
        dimensions: ['开始时间 ', '结束时间 ', '功率（kW） '],
        encode: {
          x: [0, 1],
          y: 2,
          tooltip: [0, 1, 2],
          itemName: 4
        },
        data: this_.data
      };
      var title = '';
      if (this_.data.length > 0) {
        title = '(' + this_.restMode.date.Format("yyyy-MM-dd") + ')  ' + this_.strategy.name;
      }
      newOp.title = {
        textStyle: {
          fontFamily: "微软雅黑",
          color: '#E2E2E2'
        },
        text: '运行策略 ' + title,
        //					subtext: this_.strategy == null ? '放电下限：0%' + '\t\t\t\t\t\t\t' + '充电上限：100%' : '放电下限：' + this_.strategy.dischargeLowerLimit + '%' + '\t\t\t\t\t\t\t' + '充电上限：' + this_.strategy.dischargeUpperLimit + '%',
        //					subtextStyle: {
        //						fontFamily: "微软雅黑",
        //						color: '#fff',
        //						fontSize: 14,
        //					},
        x: 'center'
      };
      newOp.xAxis = {
        scale: true,
        type: 'time',
        splitNumber: 12,
        splitLine: {
          lineStyle: {
            color: '#7C868D',
          }
        },
        axisLine: {
          lineStyle: {
            color: '#7C868D',
          }
        },
        min: new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum).Format("yyyy-MM-dd hh:mm:ss"),
        max: new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum + 1).Format("yyyy-MM-dd hh:mm:ss"),
        axisLabel: {
          formatter: function (params, index) {
            var currentYear = moment().format("YYYY");
            var currentMonth = moment().format("MM");
            var currentDay = moment().format("DD");
            var thisYear = moment(Number(params)).format("YYYY");
            var thisMonth = moment(Number(params)).format("MM");
            var thisDay = moment(Number(params)).format("DD");
            var hourAndMinite = moment(Number(params)).format("HH:mm");
            return hourAndMinite
          }
        }
      };
      this_.planStrategyChart.setOption(newOp);
    },
    list(type) {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/strategy/list/' + localStorage.getItem('stationId'),
      }).then(function (d) { //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () { });
          return;
        }
        if (d.data.code === "0") {
          this_.usingStrategyId = this_.strategyId;
          if (this_.restMode.operatingMode === '0') {
            this_.usingStrategyId = -1;
          } else if (this_.restMode.operatingMode === '1') {
            this_.usingStrategyId = this_.optimalStrategyId;
          } else if (this_.restMode.operatingMode === '2') {
            this_.usingStrategyId = this_.strategyId;
          }
          var date = new Date(this_.yearNum, this_.monthNum, this_.selectDayNum);
          this_.data = [];
          this_.strategy = null;
          this_.strategyList = d.data.data;
          if (this_.usingStrategyId == -1) {
            this_.data.push([
              date.Format('yyyy-MM-dd ') + "00:00:00",
              date.Format('yyyy-MM-dd ') + "23:59:59",
              0,
            ]);
          } else {
            if (this_.strategyList && this_.strategyList.length > 0) {
              this_.strategyList.map(function (item) {
                if (item.id == this_.usingStrategyId) {
                  this_.strategy = item;
                  item.details.map(function (x) {
                    this_.data.push([
                      date.Format('yyyy-MM-dd ') + x.startTime,
                      date.Format('yyyy-MM-dd ') + x.endTime,
                      x.power,
                    ]);
                  });
                }
              });
            }
          }
          if (type == 1) {
            this_.init("plan_strategy_chart");
          }
        } else {
          this_.$message.error(d.data.message, 2, function () { });
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () { });
          return;
        }
      });
    },
    getChartOpt() {
      var this_ = this;
      this_.parse();
      var title = '';
      if (this_.data.length > 0) {
        title = '(' + this_.restMode.date.Format("yyyy-MM-dd") + ')  ' + this_.strategy.name;
      }
      return {
        title: {
          textStyle: {
            fontFamily: "微软雅黑",
            color: '#E2E2E2'
          },
          text: '运行策略  ' + title,
          //						subtext: this_.strategy == null ? '放电下限：0%' + '\t\t\t\t\t\t\t' + '充电上限：100%' : '放电下限：' + this_.strategy.dischargeLowerLimit + '%' + '\t\t\t\t\t\t\t' + '充电上限：' + this_.strategy.dischargeUpperLimit + '%',
          //						subtextStyle: {
          //							fontFamily: "微软雅黑",
          //							color: '#fff',
          //							fontSize: 14,
          //						},
          x: 'center'
        },
        grid: {
          top: 90,
          left: 80,
          bottom: 50,
          right: 25
        },
        tooltip: {},
        xAxis: {
          scale: true,
          type: 'time',
          splitNumber: 12,
          splitLine: {
            lineStyle: {
              color: '#7C868D',
            }
          },
          axisLine: {
            lineStyle: {
              color: '#7C868D',
            }
          },
          min: new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum).Format("yyyy-MM-dd hh:mm:ss"),
          max: new Date(this_.yearNum, this_.monthNum + this_.dateFlag, this_.selectDayNum + 1).Format("yyyy-MM-dd hh:mm:ss"),
          axisLabel: {
            formatter: function (params, index) {
              var currentYear = moment().format("YYYY");
              var currentMonth = moment().format("MM");
              var currentDay = moment().format("DD");
              var thisYear = moment(Number(params)).format("YYYY");
              var thisMonth = moment(Number(params)).format("MM");
              var thisDay = moment(Number(params)).format("DD");
              var hourAndMinite = moment(Number(params)).format("HH:mm");
              return hourAndMinite
            }
          }
        },
        yAxis: {
          show: true,
          axisLine: {
            lineStyle: {
              color: '#7C868D',
            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D',
            }
          }
        },
        series: {
          type: 'custom',
          renderItem: this_.renderItem,
          label: {
            normal: {
              show: true,
              position: 'top'
            }
          },
          dimensions: ['开始时间 ', '结束时间 ', '功率（kW） '],
          encode: {
            x: [0, 1],
            y: 2,
            tooltip: [0, 1, 2],
            itemName: 4
          },
          data: this_.data
        }
      };
    },
    init(id) {
      var this_ = this;
      if (typeof (this_.restMode.date) == "undefined") {
        this_.restMode.date = new Date();
      }
      var strategyOption = this.getChartOpt()
      this_.planStrategyChart = this_.$echarts.init(document.getElementById(id));
      this_.planStrategyChart.setOption(strategyOption);
      setTimeout(() => {
        window.onresize = function () {
          this_.planStrategyChart.resize();
        }
      }, 200)
    },
    renderItem(params, api) {
      var yValue = api.value(2);
      var start = api.coord([api.value(0), yValue]);
      var size = api.size([api.value(1) - api.value(0), yValue]);
      var style = api.style();

      return {
        type: 'rect',
        shape: {
          x: start[0],
          y: start[1] <= 0 ? start[1] + 4 : start[1] - 4,
          width: size[0],
          height: 8
        },
        style: style
      };
    },
    parse() {
      var this_ = this;
      this_.data = this_.$echarts.util.map(this_.data, function (item, index) {
        var col = "#49AA19";
        if (item[2] < 0.5 && item[2] > -0.5) {
          col = "#49AA19 ";
        } else {
          col = "#F26813";
        }
        return {
          value: item,
          itemStyle: {
            normal: {
              color: col
            }
          }
        };
      });
    },
    updateStrategy() {
      if (this.timerFlag) {
        return;
      }
      var this_ = this;
      this_.getHolidays(0);
      var date = new Date(this.yearNum, this.monthNum + this.dateFlag, parseInt(this.selectDayLi.childNodes[0].innerHTML));
      this_.restMode.date = date;
      this_.restMode.operatingMode = '2';
      this_.usingStrategyId = this_.strategyId;
      if (this_.rest.includes(date.Format("yyyy-MM-dd"))) {
        this_.restMode.operatingMode = '2';
        this_.usingStrategyId = this_.strategyId;
      } else if (this_.holidays.includes(date.Format("yyyy-MM-dd"))) {
        this_.usingStrategyId = this_.optimalStrategyId;
        this_.restMode.operatingMode = '1';
      } else if (this_.shutdownSchedule.includes(date.Format("yyyy-MM-dd"))) {
        this_.restMode.operatingMode = '0';
        this_.usingStrategyId = -1;
      } else {
        if (parseInt(this_.restModeType) > 0) {
          if (date.getDay() == 0) {
            this_.restMode.operatingMode = '1';
            this_.usingStrategyId = this_.optimalStrategyId;
          }
        }
        if (parseInt(this_.restModeType) > 1) {
          if (date.getDay() == 6) {
            this_.restMode.operatingMode = '1';
            this_.usingStrategyId = this_.optimalStrategyId;
          }
        }
      }

      this_.data = [];
      this_.strategy = null;
      if (this_.usingStrategyId == -1) {
        this_.data.push([
          date.Format('yyyy-MM-dd ') + "00:00:00",
          date.Format('yyyy-MM-dd ') + "23:59:59",
          0,
        ]);
      } else {
        if (this_.strategyList && this_.strategyList.length > 0) {
          this_.strategyList.map(function (item) {
            if (item.id == this_.usingStrategyId) {
              this_.strategy = item;
              item.details.map(function (x) {
                this_.data.push([
                  date.Format('yyyy-MM-dd ') + x.startTime,
                  date.Format('yyyy-MM-dd ') + x.endTime,
                  x.power,
                ]);
              });
            }
          });
        }
      }
      this_.reflush();
    },
  }
}
</script>

<style>
@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";

.calendar {
  width: 450px;
  height: 600px;
  float: left;
  padding: 10px 0;
}

.calendar_title {
  margin-right: 10px;
  height: 27px;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #E2E2E2;
  line-height: 27px;
  font-weight: 600;
  float: left;
}

.calendar_action {
  float: right;
  margin-right: 10px;
}

.riliHeader {
  height: 64px;
  padding: 0 !important;
}

ul,
li,
ol {
  list-style: none;
  margin-bottom: 0 !important;
}

.riliHeader li,
.riliContent li {
  float: left;
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  text-align: center;
}

.riliContent {
  width: 100%;
  height: 300px;
}

.riliContent li.on {
  color: #f00;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.strategy_name {
  float: left;
  margin-left: 50px;
}

.strategy_data {
  color: rgba(255, 255, 255, 1);
  float: left;
}

.strategy {
  float: left;
  margin-left: calc(50% - 163px);
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 400;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #ddd;
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  color: #B7B8BC;
}
</style>