import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['equipList'],
  data: function data() {
    return {
      inputValue: this.equipList.map(function (equip) {
        var _equip$transformerFac;

        return (_equip$transformerFac = equip.transformerFactor) !== null && _equip$transformerFac !== void 0 ? _equip$transformerFac : 0;
      }),
      isChange: false
    };
  },
  computed: {
    hasDefaultValue: function hasDefaultValue() {
      return this.inputValue.every(function (val) {
        return typeof val === 'number';
      });
    }
  }
};